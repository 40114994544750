<template>
  <div class="pt-4 mb-6">
    <div class="w-full mb-3 flex items-center space-x-4">
      <div class="mb-3 w-1/2">
        <label class="inline-block text-[14px] text-brand-black mb-2">First Name <is-required /></label>
        <input
          type="text"
          class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
          placeholder="Type first name..."
          v-model="inviteDetails.first_name"
        />
      </div>

      <div class="mb-3 w-1/2">
        <label class="inline-block text-[14px] text-brand-black mb-2">Last Name <is-required /></label>
        <input
          type="text"
          class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
          placeholder="Type last name..."
          v-model="inviteDetails.last_name"
        />
      </div>
    </div>

    <div class="w-full mb-3 flex items-center space-x-4">
      <div class="mb-3 w-1/2">
        <label class="inline-block text-[14px] text-brand-black mb-2">Email Address <is-required /></label>
        <input
          type="text"
          class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
          placeholder="Type an email address..."
          v-model="inviteDetails.email"
        />
      </div>

      <div class="mb-3 w-1/2">
        <label class="inline-block text-[14px] text-brand-black mb-2">Contact Number <is-required /></label>
        <input
          type="text"
          class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
          placeholder="Type phone number..."
          v-model="inviteDetails.phone_no"
        />
      </div>
    </div>

    <div class="w-full mb-3 flex items-center space-x-4">
      <div class="mb-3 w-1/2">
        <label class="inline-block text-[14px] text-brand-black mb-2">Current Company <is-required /></label>
        <div class="relative w-full">
          <input
            type="search"
            id="default-search"
            class="block w-full px-4 py-4 pr-10 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
            placeholder="Current company"
            required
            v-model="inviteDetails.current_company"
          />

          <button class="absolute right-4 top-3 z-10">
            <img src="../../assets/icons/search.svg" alt="" />
          </button>
        </div>
      </div>

      <div class="mb-3 w-1/2">
        <label class="inline-block text-[14px] text-brand-black mb-2">Job Title Role <is-required /></label>
        <input
          type="text"
          class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
          placeholder="Type you job title..."
          v-model="inviteDetails.job_title"
        />
      </div>
    </div>

    <div class="w-full mb-3 flex items-center space-x-4">
      <div class="mb-3 w-1/2">
        <label class="inline-block text-[14px] text-brand-black mb-2">Qualification <is-required /></label>
        <select
          class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
          required
          v-model="inviteDetails.qualification"
        >
          <option value="">Select Qualification</option>
          <option :value="item.key" v-for="item in enums?.candidate_academic_qualifications" :key="item.key">{{ item.value }}</option>
        </select>
      </div>

      <div class="mb-3 w-1/2">
        <label class="inline-block text-[14px] text-brand-black mb-2">Years of Experience <is-required /></label>
        <select
          class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
          required
          v-model="inviteDetails.years_of_experience"
        >
          <option value="">Select Years of Experience</option>
          <option :value="item" v-for="(item, index) in 30" :key="index">{{ item }}</option>
          <!-- <option :value="item.key" v-for="item in enums?.level_of_experience" :key="item.key">{{ item.value }}</option> -->
        </select>
      </div>
    </div>

    <div class="w-full mb-3 flex items-center space-x-4">
      <div class="mb-3 w-full">
        <label class="inline-block text-[14px] text-brand-black mb-2">Add a note here <is-required /></label>
        <textarea
          type="text"
          class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
          placeholder="Add a note here"
          rows="5"
          v-model="inviteDetails.note"
        ></textarea>
      </div>
    </div>

    <div class="w-full mb-3 flex items-center space-x-4">
      <div class="mb-3 w-full">
        <label class="inline-block text-[14px] text-brand-black mb-2">Add tags <is-required /></label>
        <vue3-tags-input
          :tags="inviteDetails.tags"
          :add-tag-on-keys="[13]"
          @on-tags-changed="(tags) => (inviteDetails.tag = tags)"
          placeholder="type a word and type enter"
          class="block w-full px-3 py-3 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-grey text-[14px] disabled:bg-light-grey ring-0 outline-none focus:outline-none focus:ring-0"
          id="tag-input"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import Vue3TagsInput from 'vue3-tags-input';

defineProps({
  inviteDetails: { type: Object },
});

const store = useStore();

const enums = computed(() => {
  return store.getters['global/getEnumDetails'];
});
</script>

<style lang="scss" scoped>
#tag-input::v-deep {
  background-color: transparent !important;
  border-radius: 0.75rem !important;
  border: none !important;
  border: 1px solid #dedede !important;

  &.v3ti--focus {
    border: 1px solid #dedede !important;
    outline: none !important;
    box-shadow: none !important;

    .v3ti-content {
      input {
        outline: none !important;
        border: none !important;

        &:focus {
          outline: none !important;
          border: 1px solid transparent !important;
        }
      }
    }
  }
}
</style>
