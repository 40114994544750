<template>
  <div class="z-50 fixed inset-0 bg-[#1A1A1A33]/20 backdrop-blur-sm">
    <div class="relative md:px-4 px-6 min-h-screen flex items-center justify-center">
      <div class="inset-x-0 relative w-full max-h-[520px] overflow-y-scroll border rounded-[20px] border-brand-border scrollbar-hide md:w-[30%]">
        <div class="w-full bg-white md:rounded rounded-b-none rounded-lg h-fit relative p-8">
          <div class="flex justify-end mb-6">
            <TamButton @click="closeModal" color="black" :icon="CloseOutline" />
          </div>
          <p class="text-2xl font-semibold text-center text-[##1A1A1A] mb-4">Change Status</p>

          <p class="text-[##1A1A1A] mb-2.5">Application Status</p>

          <select v-model="status" class="w-full rounded-xl border-[#DEDEDE] py-4 px-5 mb-8 focus:ring-0 focus:outline-none">
            <option value="" disabled>Change Status</option>
            <option value="open">Open</option>
            <option value="closed">Close</option>
          </select>

          <div class="flex w-full space-x-4">
            <button class="border border-[#111827] rounded-xl py-5 px-6 bg-white text-brand-black w-1/2" @click="closeModal">Cancel</button>
            <button class="border border-[#111827] rounded-xl py-5 px-6 bg-[#CB0156] text-white w-1/2" @click="changeStatus">Change Status</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { CloseOutline } from '@/icons';
import TamButton from '@/components/TamButton.vue';
import { alert } from '@/utils/helper';
import { useStore } from 'vuex';

const store = useStore();

const status = ref();

const props = defineProps({ id: String });

const emit = defineEmits(['onClose']);
const closeModal = () => {
  emit('onClose');
};

const changeStatus = async () => {
  try {
    await store.dispatch('global/updateSingleJobStatus', { id: props.id, status: status.value });
    alert('Status Updated', 'success');
    closeModal();
    window.location.reload();
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    console.log(error);
    // alert(errorMessage(error), 'error');
  }
};

onMounted(() => {
  console.log(props.id, "STATUS");
});
</script>
