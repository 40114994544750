<template>
  <div class="table-responsive overflow-x-auto">
    <table class="w-full text-sm text-left text-gray-500 p-4">
      <thead class="text-xs text-gray-700 uppercase bg-light-background">
        <tr>
          <th scope="col" class="px-6 py-3 whitespace-nowrap">PLAN</th>
          <th scope="col" class="px-6 py-3 whitespace-nowrap">PAYMENT CHANNEL</th>
          <th scope="col" class="px-6 py-3 whitespace-nowrap">AMOUNT</th>
          <th scope="col" class="px-6 py-3 whitespace-nowrap">STATUS</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in payments" :key="item" class="bg-white border-b hover:bg-gray-50">
          <td scope="row" class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap ">
            {{ item.plan.name }}
          </td>
          <td scope="row" class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap ">
            <img :src="processorIcons[item.payment_gateway_type]" width="80" alt="image" />
          </td>
          <td class="px-6 py-4 font-light">
            <div class="flex flex-nowrap items-center">
              <span>{{ displayCUrrencySymbol(item.currency_coode) }}</span> {{ formatNumber(item.amount) }}
            </div>
          </td>
          <td class="px-6 py-4 font-light">{{ transactionStatus(item.transactions) }}</td>
          <td class="px-6 py-4 text-right">
            <TamButton @click="showDetail(item)" class="ml-3 md:ml-0 w-1/2" color="infoLight" label="View" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <payment-detail :open="showModal" :payment="payment" :processor-icons="processorIcons" :currencyCode="currencyCode"
    @on-close="closeModal" />
</template>

<script setup>
import PaymentDetail from "@/components/subscriptions/PaymentDetail.vue";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { formatNumber } from "@/utils/helper";
import { Stripe, Payoneer, Paypal, Paystack } from "@/icons";
import TamButton from "@/components/TamButton.vue";

const props = defineProps({
  payments: { type: Array, default: [] },
});

const payment = ref({});
const showModal = ref(false);

const store = useStore();

const processorIcons = {
  stripe: Stripe,
  paystack: Paystack,
  paypal: Paypal,
  payoneer: Payoneer,
};

const currencyCodes = ref({
  usd: "$",
  ngn: "₦",
});

const currencyCode = ref('');

const displayCUrrencySymbol = (currency_coode) => currency_coode ? currencyCodes.value[currency_coode] : ''

const showDetail = (val) => {
  const { transactions } = val;
  const transaction = transactions.reduce((pv, cv) => {
    pv.total_amount = Math.floor(pv.total_amount + cv.total_amount);
    return pv.total_amount
  }, { total_amount: 0 });
    payment.value = {
    ...val,
    amount: formatNumber(transaction),
  };
  val.currency_coode ? currencyCode.value = currencyCodes.value[val.currency_coode] : ''
  showModal.value = true;
};

const closeModal = () => {
  payment.value = {};
  showModal.value = false;
};

const transactionStatus = (transactions) => {
  if (!Array.isArray(transactions)) {
    return "NA";
  }
  const transaction = transactions[transactions.length - 1];
  if (transaction) {
    return transaction.transaction_status;
  }
  return "";
};

const getSettings = async () => {
  try {
    await store.dispatch("global/getSettings");
  } catch (error) {
    console.log(error);
  }
};

onMounted(async () => {
  //await getSettings();
});
</script>
