<template>
  <form @submit.prevent="addCandidate" id="csvForm">
    <div class="pt-4 mb-6">
      <div class="text-center mb-4">
        <p>
          Download our template and <br class="hidden md:inline-block" />
          fill it with the details provided
        </p>
      </div>

      <div class="mb-6">
        <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Upload your file</label>
        <div
          class="border border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 w-full p-3 px-6 sm:px-4 flex justify-between items-center"
        >
          <label for="dropzone-file" class="flex items-center cursor-pointer">
            <img src="../../assets/icons/upload2.svg" class="mr-4" alt="" />
            <div>
              <p>Tap to upload</p>
              <small>CSV | 10MB max.</small>
            </div>
            <input ref="selectedFile" id="dropzone-file" name="file" type="file" class="hidden" accept=".csv,.xls" @change="getCSVData" />
          </label>
          <label for="dropzone-file" class="bg-brand-black px-6 sm:px-4 py-3 text-white rounded-xl block cursor-pointer"> Upload </label>
        </div>
      </div>

      <div class="text-center mb-6">
        <a href="/files/talents.ods" download class="inline-flex items-center space-x-2 bg-light-yellow p-3 rounded-lg">
          <img src="../../assets/icons/document.svg" alt="" />
          <span class="text-[14px]">Download Template</span>
        </a>
      </div>

      <div class="overflow-x-auto custom-scroll">
        <table class="w-full text-sm text-left text-gray-500 p-4">
          <thead class="uppercase bg-brand-black text-white">
            <tr>
              <th scope="col" class="px-6 py-4 text-[13px] whitespace-nowrap font-bold capitalize text-left" v-for="item in tableDetails" :key="item">
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody v-if="csvData.length">
            <tr v-for="item in csvData" :key="item" class="bg-white border-b hover:bg-gray-50">
              <td class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{{ item.First_Name }}</td>
              <td class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{{ item.Last_Name }}</td>
              <td class="px-6 py-4 font-light">{{ item.Phone_No }}</td>
              <td class="px-6 py-4">{{ item.Email }}</td>
              <td class="px-6 py-4">{{ item.Current_Company }}</td>
              <td class="px-6 py-4">{{ item.Job_Title }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="6">
                <div class="p-4">
                  <p>No data uploaded yet</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="pt-2 flex space-x-6 items-center">
      <button type="button" @click.prevent="$emit('close')" class="bg-white p-4 py-3 border border-brand-black rounded-lg w-1/2">Cancel</button>
      <button type="submit" class="bg-brand-black text-white p-4 py-3 rounded-lg w-1/2">Submit</button>
    </div>
  </form>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { errorMessage } from '@/utils/helper';

const emit = defineEmits(['close']);
const props = defineProps({
  jobId: { type: String },
});

const store = useStore();
const toast = useToast();
const loading = ref(false);
const tableDetails = ref(['First Name', 'Last Name', 'Phone Number', 'Email', 'Current Company', 'Job Title']);

const addCandidate = async () => {
  try {
    loading.value = true;
    const formData = new FormData(document.querySelector('#csvForm'));
    const { message } = await store.dispatch('global/jobInviteCSV', { data: formData, jobId: props.jobId });

    toast.success(message, {
      timeout: 3000,
      hideProgressBar: true,
    });

    emit('close');
  } catch (error) {
    toast.error(errorMessage(error) || 'Something went wrong (Please check internent connection)', {
      timeout: 5000,
      hideProgressBar: true,
    });
    loading.value = false;
  }
};
</script>

<script>
export default {
  data() {
    return {
      csvData: [],
    };
  },
  methods: {
    getCSVData() {
      const self = this;
      this.$papa.parse(document.querySelector('#dropzone-file').files[0], {
        download: true,
        header: true,
        skipEmptyLines: true,
        complete: function (answer) {
          for (let i = 0; i < answer.data.length; i++) {
            self.csvData.push(answer.data[i]);
          }
        },
      });
    },
  },
};
</script>
