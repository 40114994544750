<template>
  <mini-modal :hasDone="false" @cancel="allJobs" :modelValue="true" title=".">
    <div class="">
      <div class="mx-auto w-fit mb-5">
        <svg class="relative overflow-visible" style="" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2362_8704)">
            <path
              d="M24 0C19.2533 0 14.6131 1.40758 10.6663 4.04473C6.71954 6.68188 3.64341 10.4302 1.8269 14.8156C0.0103989 19.201 -0.464881 24.0266 0.461164 28.6822C1.38721 33.3377 3.67299 37.6141 7.02945 40.9706C10.3859 44.327 14.6623 46.6128 19.3178 47.5388C23.9734 48.4649 28.799 47.9896 33.1844 46.1731C37.5698 44.3566 41.3181 41.2805 43.9553 37.3337C46.5924 33.3869 48 28.7468 48 24C48 17.6348 45.4714 11.5303 40.9706 7.02944C36.4697 2.52856 30.3652 0 24 0ZM37.64 18.21L22.58 35.29C22.337 35.5638 22.0423 35.7869 21.7128 35.9465C21.3833 36.1061 21.0255 36.199 20.66 36.22H20.48C19.7979 36.2242 19.1385 35.9747 18.63 35.52L10.57 28.37C10.0157 27.878 9.67955 27.186 9.63547 26.4462C9.5914 25.7063 9.84303 24.9793 10.335 24.425C10.827 23.8707 11.519 23.5345 12.2588 23.4905C12.9987 23.4464 13.7257 23.698 14.28 24.19L20.28 29.48L33.49 14.48C33.9807 13.9244 34.6719 13.5864 35.4118 13.5405C36.1516 13.4945 36.8794 13.7443 37.435 14.235C37.9906 14.7256 38.3286 15.4169 38.3745 16.1568C38.4205 16.8966 38.1707 17.6244 37.68 18.18L37.64 18.21Z"
              fill="#089115"
            />
          </g>
          <defs>
            <clipPath id="clip0_2362_8704">
              <rect width="48" height="48" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="text-[#000000] text-center justify-center relative" style="font: 700 32px 'Albert Sans', sans-serif">You’ve posted a Job!</div>
      <div class="text-[#000000] text-center w-fit relative mx-auto" style="font: 300 14px/130% 'Albert Sans', sans-serif">
        Your opening for "{{ job.role_title }}" is pending approval!. <br />We’ll notify you when it's approved and when candidates start to apply.
        <br /><br />Share on your social media for more visibility!
      </div>
    </div>
    <div slot="footer" class="flex flex-row justify-between">
      <ShareNetwork
        class="border rounded-xl p-3 mx-1.5"
        v-for="network in networks"
        :network="network.network"
        :key="network.network"
        :style="{ borderColor: network.color, color: network.color }"
        :url="sharing.url"
        :title="sharing.title"
        :description="sharing.description"
        :quote="sharing.quote"
        :hashtags="sharing.hashtags"
        :twitterUser="sharing.twitterUser"
      >
        <div class="flex items-center">
          <img :src="network.icon" class="mr-1" />
          <span>{{ network.name }}</span>
        </div>
      </ShareNetwork>
      <div class="border border-[#12080F] rounded-xl p-3 mx-1 cursor-pointer" @click="copy">
        <div class="flex items-center">
          <img src="@/assets/icons/link.svg" class="mr-1" />
          <button type="button" v-clipboard:copy="sharing.url" v-clipboard:error="onError" class="whitespace-nowrap text-[#12080F] text-base">
            Copy Link
          </button>
        </div>
      </div>
    </div>
  </mini-modal>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import MiniModal from '../MiniModal.vue';
import { role, createJobLink } from '@/utils/helper';

const props = defineProps({
  job: {
    type: Object,
    requires: true,
    default: () => ({}),
  },
});

const router = useRouter();

const allJobs = () => router.push('/jobs');

const sharing = computed(() => {
  const Job = props.job;
  return {
    url: `${process.env.VUE_APP_DOMAIN}${createJobLink(Job)}`,
    title: `We're hiring for the role of ${role(Job)}`,
    // description: 'This week, I’d like to introduce you to "Vite", which means "Fast". It’s a brand new development setup created by Evan You.',
    hashtags: 'recruitment,careerbuddy,hiring',
  };
});

const networks = ref([
  //{ network: 'facebook', name: 'Facebook', icon: require('@/assets/icons/fb.svg'), color: '#415E9B' },
  { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
  { network: 'twitter', name: 'Twitter', icon: require('@/assets/icons/twi.svg'), color: '#1DA1F2' },
  { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
]);
</script>
