<template>
  <section>
    <ul
      class="flex flex-wrap justify-between mb-8 text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700"
    >
      <div class="flex items-center">
        <li class="mr-2">
          <a
            href="#"
            @click.prevent="tab = 0"
            aria-current="page"
            :class="{ 'border-b-2 border-brand-primary': tab === 0 }"
            class="inline-block p-4 rounded-t-lg dark:bg-gray-800 font-semibold text-[16px]"
            >Plans
            <span class="bg-brand-primary text-white px-2 py-1 rounded-full ml-2">{{
              subscriptions.length
            }}</span></a
          >
        </li>
        <li class="mr-2">
          <a
            href="#"
            @click.prevent="historyTab"
            :class="{ 'border-b-2 border-brand-primary': tab === 1 }"
            class="inline-block p-4 rounded-t-lg hover:text-gray-600 dark:hover:bg-gray-800 dark:hover:text-gray-300 font-semibold text-[16px] text-black/[.5]"
            >Payment History</a
          >
        </li>
      </div>
    </ul>

    <div>
      <plans v-if="tab == 0" />
      <payment-history :payments="payments" v-else />
    </div>
  </section>

  <loading v-if="loading" />
</template>

<script setup>
import Plans from "@/components/subscriptions/Plans.vue";
import PaymentHistory from "@/components/subscriptions/PaymentHistory.vue";
import Loading from "@/components/LoadingOverlay.vue";
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const route = useRoute();
const store = useStore();
const tab = ref(0);
const loading = ref(false);

const subscriptions = computed(() => {
  return store.getters["global/getPlans"] || [];
});

const payments = computed(() => {
  return store.getters["global/getPayments"] || [];
});

const historyTab = () => {
  tab.value = 1;
  getSubscriptionPayments();
};

const getSubscriptions = async () => {
  loading.value = true;
  try {
    await store.dispatch("global/getSubscriptionPlans");
  } catch (error) {
    toast.error(errorMessage(error), {
      timeout: 3000,
      hideProgressBar: true,
    });
  } finally {
    loading.value = false;
  }
};

const getSubscriptionPayments = async () => {
  loading.value = true;
  try {
    await store.dispatch("global/getSubscriptionPayments");
  } catch (error) {
    toast.error(errorMessage(error), {
      timeout: 3000,
      hideProgressBar: true,
    });
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  const tabQuery = route.query.tab;
  if (tabQuery) {
    historyTab();
  }
  await getSubscriptions();
});
</script>
