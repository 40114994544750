<template>
  <main>
    <div class="pt-4 grid md:grid-cols-2 md:gap-6 gap-4">
      <div>
        <label for="email-address-icon" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Fisrt Name <is-required /></label>
        <input
          type="text"
          id="first_name"
          class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full md:px-5 md:py-4 p-4 focus:outline-none outline-none"
          placeholder="First Name"
          v-model="candidate.first_name"
          minlength="3"
          required
        />
      </div>
      <div class="">
        <label for="email-address-icon" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Last Name <is-required /></label>
        <input
          type="text"
          id="last_name"
          class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full md:px-5 md:py-4 p-4 focus:outline-none outline-none"
          placeholder="Last Name"
          v-model="candidate.last_name"
          minlength="3"
          required
        />
      </div>

      <div class="">
        <label for="email-address-icon" class="block mb-2 text-brand-black text-[14px] leading-[160%]"> Work Email <is-required /></label>
        <input
          type="email"
          id="email"
          placeholder="example: careerbuddy.com"
          class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full md:px-5 md:py-4 p-4 focus:outline-none outline-none"
          v-model="candidate.email"
          required
        />
      </div>
      <div class="">
        <label for="email-address-icon" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Contact Number <is-required /></label>
        <input
          type="text"
          id="company_name"
          placeholder="+2348111344635"
          class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full md:px-5 md:py-4 p-4 focus:outline-none outline-none"
          v-model="candidate.phone_no"
          required
        />
      </div>

      <div class="">
        <label for="email-address-icon" class="block mb-2 text-brand-black text-[14px] leading-[160%]"> Current Company <is-required /></label>
        <div class="relative">
          <input
            type="text"
            placeholder="Careerbuddy"
            id="password-icon"
            class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full md:px-5 md:py-4 p-4 focus:outline-none outline-none"
            v-model="candidate.current_company"
            required
          />
        </div>
      </div>
      <div class="">
        <label for="email-address-icon" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Job Title <is-required /></label>
        <div class="relative">
          <input
            type="text"
            placeholder="JobTilte"
            id="password-icon"
            class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full md:px-5 md:py-4 p-4 focus:outline-none outline-none"
            v-model="candidate.job_title"
            required
          />
        </div>
      </div>
      <div class="">
        <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Qualification <is-required /></label>
        <select
          required
          v-model="candidate.qualification"
          id="role-type"
          name="role-type"
          autocomplete="role-type"
          class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full md:px-5 md:py-4 p-4 focus:outline-none outline-none"
        >
          <option disabled value="">Select One</option>
          <option class="" v-for="item in qualification" :key="item" :value="item.value">{{ item.text }}</option>
        </select>
      </div>
      <div>
        <label for="work-type" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Experience <is-required /></label>
        <input
          type="text"
          placeholder="JobTilte"
          id="password-icon"
          class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full md:px-5 md:py-4 p-4 focus:outline-none outline-none"
          v-model="candidate.years_of_experience"
          required
        />
        <!-- <select
          required
          v-model="candidate.years_of_experience"
          id="role-type"
          name="role-type"
          autocomplete="role-type"
          class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full md:px-5 md:py-4 p-4 focus:outline-none outline-none"
        >
          <option disabled value="">Select One</option>
          <option class="" v-for="item in experience" :key="item" :value="item.value">{{ item.text }}</option>
        </select> -->
      </div>
      <!-- </div> -->
    </div>
    <div class="">
      <div class="mt-6">
        <label for="email-address-icon" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Add a note here</label>
        <textarea
          rows="6"
          id="note"
          placeholder="Add a note here"
          class="border-light-grey text-[#090E11] text-sm rounded-lg focus:ring-0 block w-full md:px-5 md:py-4 p-4 focus:outline-none outline-none"
          v-model="candidate.note"
          required
        ></textarea>
      </div>

      <div class="mt-6">
        <label for="tags" class="block mb-2 text-brand-black text-[14px] leading-[160%]">Add tags<is-required /></label>
        <vue3-tags-input
          :tags="candidate.tags"
          :add-tag-on-keys="[13]"
          @on-tags-changed="handleChangeTag"
          placeholder="e.g UI/UX"
          class="mt-1 block w-full border-1 border-[#1B224B1A] py-2 px-4 rounded-md placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm"
        />

        <div class="flex flex-wrap items-center mt-3" v-if="candidate.tags.length">
          <div
            class="mr-4 mb-2 flex items-center rounded-[30px] px-[16px] py-[8px] bg-[#F3F3F5] font-medium text-[15px]"
            v-for="(item, i) in candidate.tags"
            :key="i"
          >
            <span class="mr-1">{{ item }}</span>
            <img @click="candidate.tags.splice(i, 1)" src="@/assets/icons/cancel.svg" class="cursor-pointer" />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-8 grid md:grid-cols-2 md:gap-x-5 md:gap-y-6 gap-4">
      <button
        type="button"
        class="w-full border-brand-black text-brand-black border md:px-5 md:py-4 p-4 rounded-lg disabled:bg-opacity-80 cursor-pointer"
        @click="close"
      >
        Cancel
      </button>
      <!-- :disabled="loading" -->
      <button
        @click="sendInvite"
        type="submit"
        class="w-full bg-brand-black text-white md:px-5 md:py-4 p-4 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
      >
        {{ loading ? 'Loading...' : 'Submit' }}
        <btn-loader :loading="loading" />
      </button>
    </div>
  </main>
</template>

<script setup>
import { alert } from '@/utils/helper';
import { computed, ref } from 'vue';
import Vue3TagsInput from 'vue3-tags-input';
import { useStore } from 'vuex';

const store = useStore();
const props = defineProps({ info: { type: Object } });

const candidate = ref({
  first_name: '',
  last_name: '',
  email: '',
  phone_no: '',
  current_company: '',
  job_title: '',
  qualification: '',
  years_of_experience: '',
  note: '',
  tags: [],
});
const experience = ref([
  { text: 'Entry Level', value: 'entry_level' },
  { text: 'Junior Level', value: 'junior_level' },
  { text: 'Mid Level', value: 'mid_level' },
  { text: 'Senior Level', value: 'senior_level' },
  { text: 'Lead', value: 'lead' },
  { text: 'Executive', value: 'executive' },
]);
const qualification = ref([
  { text: 'Undergraduate', value: 'undergraduate' },
  { text: 'Bachelors (Bsc)', value: 'bsc' },
  { text: 'Masters (Msc)', value: 'msc' },
  { text: 'Doctorate (Phd)', value: 'phd' },
]);
const disabled = ref(true);
const loading = ref(false);

const emit = defineEmits(['onClose']);

const close = () => {
  emit('onClose');
};

const sendInvite = async () => {
  try {
    loading.value = true;
    const res = await store.dispatch('global/inviteToJob', { id: props.info.uuid, details: candidate.value });
    if (res.status === true) {
      alert(res.message, 'success');
    }
    close();
  } catch (error) {
    if (error.response && error.response.status === 422) {
      console.log(error.response);
      alert(error.response.data.message, 'error');
    }
    loading.value = false;
  }
};
const handleChangeTag = (tags) => {
  candidate.value.tags = tags;
};

const checkDisability = computed(() => {
  return !candidate ? (disabled.value = false) : (disabled.value = true);
  // return (userDetails.value.first_name && userDetails.value.last_name && userDetails.value.email !== '') || userDetails.value.company_name !== ''
  //   ? (disabled.value = false)
  //   : (disabled.value = true);
});
</script>
