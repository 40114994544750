<template>
  <div>
    <div class="py-5">
      <div class="bg-white rounded-lg p-3 mb-4">
        <div class="col-span-6 mb-6">
          <div class="flex items-center">
            <img :src="userDetails.employer_user.profile_picture" class="w-[80px] mr-3" />
            <div>
              <small class="text-[#F0386A] text-[14px] underline"
                >RE: {{ details?.role_title }}</small
              >
              <h3 class="font-semibold text-[#12080F] text-[20px]">
                {{ userDetails?.name }}
              </h3>
              <div class="flex flex-wrap items-center">
                <span class="text-[#060613]/[.6] text-[14px]">{{
                  userDetails.employer_user.role_at_company || "N/A"
                }}</span>
                <span
                  class="px-6 mx-4 border-x border-[#696971]/[.3] text-[#060613]/[.6] text-[14px]"
                  >{{ years_of_experience }} working</span
                >
                <span
                  class="text-[#060613]/[.6] text-[14px]"
                  v-if="userDetails?.employer_user?.country_id"
                  >{{ state(userDetails?.employer_user?.state_id) + ""
                  }}<span v-if="userDetails?.employer_user?.state_id">,</span>
                  {{ country(userDetails?.employer_user?.country_id) }}</span
                >
                <span v-else class="text-[#060613]/[.6] text-[14px]">N/A</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-6 mb-6">
          <label for="job" class="block text-base font-semibold text-[#090E11]/70 mb-2"
            >Pitch Message</label
          >
          <textarea
            id="message"
            rows="4"
            class="block w-full rounded-lg  border-1 border-[#1B224B1A] p-4 placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm bg-[#FCFCFC]"
            placeholder="Write your thoughts here..."
            v-model="details.pitch_message"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps({
  details: { type: Object, default: () => ({}) },
  userDetails: { type: Object, default: () => ({ employer_user: {} }) },
});

const years_of_experience = computed(() => {
  const yr = props.userDetails?.employer_user?.years_of_experience || 1;
  return yr < 2 ? `${yr} year ` : `${yr} years `;
});

const state = (state_id) => {
  const state = store.getters["global/getState"](state_id);
  return state?.name || '';
};

const country = (country_id) => {
  const country = store.getters["global/getCountry"](country_id);
  return country?.name || '';
};
</script>
