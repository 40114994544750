<template>
  <div class="pt-4 mb-6">
    <form class="text-center p-8" id="resumeForm" enctype="multipart/form-data">
      <img src="../../assets/icons/drag.svg" alt="" class="mx-auto" />
      <div class="my-4 mt-8">
        <h2 class="text-[18px] text-brand-black font-semibold">Drag & Drop here to upload</h2>
        <small class="inline-block text-[14px] my-2 text-light-gray">Or select file from your computer</small>
      </div>
      <tam-button color="black" label="Upload File" :icon="Uploadline" class="rounded-xl text-[14px]" />
      <label
        for="resume"
        class="bg-brand-black p-4 text-white rounded-lg px-6 text-[14px] inline-flex items-center space-x-2 disabled:bg-brand-black/30 cursor-pointer"
      >
        <input type="file" id="resume" name="file" @change="uploadResume" class="hidden" />
        <img src="../../assets//icons/uploadline.svg" class="inline-block" alt="" />
        <span>Upload File</span>
      </label>
      <p><small class="inline-block text-[14px] my-2 text-red-600 font-bold">NB: This feature is currently disabled</small></p>
    </form>
  </div>

  <loading v-if="loading" />
</template>

<script setup>
import { Uploadline } from '@/icons';
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import Loading from '@/components/LoadingOverlay.vue';
import { errorMessage } from '@/utils/helper';

const props = defineProps({
  inviteDetails: { type: Object },
});
const emit = defineEmits(['confirm']);

const store = useStore();
const loading = ref(false);

const uploadResume = async ($event) => {
  loading.value = true;
  try {
    const formData = new FormData(document.querySelector('#resumeForm'));
    const fileUrl = await store.dispatch('global/uploadFileUrl', formData);
    await getResumeInfo(fileUrl);
  } catch (error) {
    toast.error(errorMessage(error) || 'Something went wrong (Please check internent connection)', {
      timeout: 5000,
      hideProgressBar: true,
    });
    console.log(error)
  } finally {
    loading.value = false;
  }
};

const getResumeInfo = async (url) => {
  loading.value = true;
  try {
    const result = await store.dispatch('global/getInfoFromResumeParser', url);

    //Validate result
    const infoValues = Object.values(result).filter((item) => item != null);
    if (!infoValues.length) return;

    //Update details
    const name = result?.name?.split(' ');
    props.inviteDetails.first_name = name[0] || '';
    props.inviteDetails.last_name = name[1] || '';
    props.inviteDetails.email = result?.email;
    props.inviteDetails.phone_no = result?.phone;
    props.inviteDetails.tag = result?.skills || [];

    emit('confirm');
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {});
</script>
