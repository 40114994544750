<template>
  <div v-if="open" class="fixed inset-0 z-40">
    <!-- OVERLAY -->
    <div class="fixed inset-0 bg-gray-900 opacity-70 backdrop-blur-3xl"></div>
    <!-- FILTER -->
    <div
      class="fixed top-0 bottom-0 right-0 bg-white max-w-lg w-9/12 md:w-1/2 p-5 rounded-tl-lg rounded-bl-lg"
    >
      <div class="flex justify-end items-center">
        <img
          src="@/assets/icons/close-outline.svg"
          class="cursor-pointer"
          @click="closeModal"
        />
      </div>
      <div class="w-full mt-3 mb-4"></div>
      <div class="overflow-y-auto overflow-x-hidden h-[85vh]">
        <div class="bg-[#F6F6F9] rounded-lg p-4 py-6">
          <div
            class="flex justify-between items-center pb-6 mb-6 border-b border-[#E7E7E7]"
          >
            <div>
              <small class="inline-block text-[14px] text-[#979EA8] mb-2">
                Subscripion name
              </small>
              <h3 class="text-[#001A29] text-[20px] font-bold ">
                {{ payment.plan.name }}
              </h3>
            </div>
            <div
              class="rounded-3xl text-white p-2 px-3 text-[12px] font-medium "
              :class="statusClass"
            >
              {{ transaction?.transaction_status }}
            </div>
          </div>

          <div>
            <small class="inline-block text-[16px] text-[#001A29] mb-2">
              Payment details
            </small>

            <div class="bg-white rounded-lg p-4 py-6">
              <div class="grid grid-cols-2 items-center mb-4 gap-x-8">
                <div>
                  <small class="inline-block text-[14px] text-[#979EA8] mb-2">
                    Payment date
                  </small>
                  <p class="text-[14px] text-[#001A29] ">
                    {{ transactionDate }}
                  </p>
                </div>
                <div>
                  <small class="inline-block text-[14px] text-[#979EA8] mb-2">
                    Amount paid
                  </small>
                  <p class="text-[14px] text-[#001A29] ">
                    {{ currencyCode }}{{ payment.amount }}
                  </p>
                </div>
              </div>

              <div class="grid grid-cols-2 items-center gap-x-8">
                <div>
                  <small class="inline-block text-[14px] text-[#979EA8] mb-2">
                    Payment Expires
                  </small>
                  <p class="text-[14px] text-[#001A29] ">
                    {{
                      payment?.expires_at
                        ? moment(payment.created_at).format("MMMM Do YYYY, h:mm:ss a")
                        : "-"
                    }}
                  </p>
                </div>
                <div>
                  <small class="inline-block text-[14px] text-[#979EA8] mb-2">
                    Payment Channel
                  </small>
                  <img
                    :src="processorIcons[payment.payment_gateway_type]"
                    width="80"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import moment from "moment";
import { computed } from "vue";

const props = defineProps({
  payment: { type: Object, default: () => ({ transactions: [] }) },
  processorIcons: { type: Object, default: {} },
  currencyCode: { type: String, default: "" },
  open: { type: Boolean },
});

const emit = defineEmits(["onClose"]);
const closeModal = () => {
  emit("onClose");
};

const transaction = computed(() => {
  if (props.payment.transactions.length < 1) {
    return { transaction_status: '' }
  }
  return props.payment.transactions[props.payment.transactions.length - 1]
})

const transactionDate = computed(() => {
  if (props.payment.transactions.length < 1) {
    return 'Not available'
  }
  const transaction = props.payment.transactions[props.payment.transactions.length - 1]
  return moment(transaction.updated_at).format("MMMM Do YYYY, h:mm:ss a")
});

const statusClass = computed(() => {
  return {
    "bg-[#25D06A]" : transaction.value.transaction_status === 'active',
    "bg-black" : transaction.value.transaction_status === 'pending',
    "bg-[#e7384f]" : transaction.value.transaction_status === 'expired',
  }
});
</script>
