<template>
  <div v-if="open" class="fixed inset-0 z-40">
    <!-- OVERLAY -->
    <div class="fixed inset-0 bg-gray-900 opacity-70 backdrop-blur-3xl"></div>
    <!-- FILTER -->
    <div class="fixed top-0 bottom-0 right-0 bg-white max-w-xl w-9/12 md:w-1/2 p-5">
      <div class="flex justify-between items-center mb-5">
        <h2 class="font-semibold text-[22px]">Filter</h2>
        <TamButton @click="closeModal" color="white" :icon="CloseOutline" />
      </div>

      <div class="overflow-y-auto h-[85vh] custom-scroll">
        <div class="px-2">
          <div class="grid grid-cols-2 gap-6 mb-6">
            <div>
              <div class="mb-8">
                <div class="flex items-center justify-between mb-3">
                  <div class="flex items-center space-x-2">
                    <label class="inline-block text-[14px] text-brand-black">Minimum experience</label>
                    <!-- <span class="inline-grid place-content-center py-1 px-5 rounded-2xl bg-light-yellow text-brand-black text-[14px]">2</span> -->
                  </div>
                  <img src="../../assets/icons/chevron-down.svg" alt="" />
                </div>

                <div class="bg-light-gray-100 h-auto custom-scroll p-4 rounded-xl">
                  <div class="flex items-center mb-6 space-x-3" v-for="item in level_of_experience" :key="item.key">
                    <input
                      type="radio"
                      :value="item.key"
                      :checked="filterVars.includes(item.value) && filter.min_experience == item.value"
                      @change="updateFilter($event, 'min_experience', item.value)"
                      name=""
                      class="w-4 h-4 text-brand-black bg-white border-2 border-brand-black focus:ring-brand-black focus:ring-0 outline-none focus:outline-none"
                    />
                    <label for="green-radio" class="text-sm font-medium text-brand-black capitalize">{{ item.value }}</label>
                  </div>
                </div>
              </div>

              <div class="mb-8">
                <div class="flex items-center justify-between mb-3">
                  <div class="flex items-center space-x-2">
                    <label class="inline-block text-[14px] text-brand-black">Work Preference</label>
                  </div>
                  <img src="../../assets/icons/chevron-down.svg" alt="" />
                </div>

                <div class="bg-light-gray-100 h-auto p-4 rounded-xl">
                  <div class="flex items-center mb-6 space-x-3" v-for="item in enums?.job_work_types" :key="item.key">
                    <input
                      type="radio"
                      :value="item.key"
                      :checked="filterVars.includes(item.value)"
                      @change="updateFilter($event, 'work_type', item.value)"
                      name=""
                      class="w-4 h-4 text-brand-black bg-white border-2 border-brand-black focus:ring-brand-black focus:ring-0 outline-none focus:outline-none"
                    />
                    <label for="green-radio" class="text-sm font-medium text-brand-black capitalize">{{ item.value }}</label>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="mb-6">
                <div class="flex items-center justify-between mb-3">
                  <div class="flex items-center space-x-2">
                    <label class="inline-block text-[14px] text-brand-black">Maximum experience</label>
                    <!-- <span class="inline-grid place-content-center py-1 px-5 rounded-2xl bg-light-yellow text-brand-black text-[14px]">1</span> -->
                  </div>
                  <img src="../../assets/icons/chevron-down.svg" alt="" />
                </div>

                <div class="bg-light-gray-100 h-auto p-4 rounded-xl">
                  <div class="flex items-center mb-6 space-x-3" v-for="item in level_of_experience" :key="item.key">
                    <input
                      type="radio"
                      :value="item.key"
                      :checked="filterVars.includes(item.value) && filter.max_experience == item.value"
                      @change="updateFilter($event, 'max_experience', item.value)"
                      name=""
                      class="w-4 h-4 text-brand-black bg-white border-2 border-brand-black focus:ring-brand-black focus:ring-0 outline-none focus:outline-none"
                    />
                    <label for="green-radio" class="text-sm font-medium text-brand-black capitalize">{{ item.value }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h2 class="text-[14px] font-semibold border-b">
              <span class="inline-block border-b-2 border-brand-black py-2 px-4">OTHER FILTERS</span>
            </h2>

            <div class="mt-6">
              <label for="" class="text-sm font-medium text-brand-black mb-3 inline-block">Job Status</label>
              <select
                class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
                @change="updateFilter($event, 'job_status')"
              >
                <option value="">Choose</option>
                <option value="pending" :selected="filterVars.includes('pending')">Pending</option>
                <option value="active" :selected="filterVars.includes('active')">Active</option>
                <option value="inactive" :selected="filterVars.includes('inactive')">Inactive</option>
              </select>
            </div>

            <div class="mt-6">
              <label for="" class="text-sm font-medium text-brand-black mb-3 inline-block">Application Status</label>
              <select
                class="block w-full px-4 py-4 text-sm text-brand-black border border-light-grey rounded-xl bg-white placeholder:text-light-gray text-[14px]"
                @change="updateFilter($event, 'status')"
              >
                <option value="">Choose</option>
                <option :value="item.key" v-for="item in enums?.job_statuses" :key="item.key" :selected="filterVars.includes(item.value)">
                  {{ item.value }}
                </option>
              </select>
            </div>

            <div class="mt-6">
              <label for="" class="text-sm font-medium text-brand-black mb-3 inline-block">Date posted</label>
              <div>
                <Datepicker v-model="dateSelected" @update:model-value="selectDate" class="border border-light-grey rounded-xl h-[30px]" />
              </div>
            </div>
          </div>

          <div class="pt-2 flex space-x-6 items-center mb-3 mt-8">
            <button
              type="button"
              @click.prevent="clear"
              class="bg-white text-[#FE207E] font-medium p-4 py-3 rounded-lg w-1/2 border border-brand-black"
            >
              Clear all
            </button>
            <button @click="apply" class="bg-brand-black text-white p-4 py-3 rounded-lg w-1/2">Apply filter</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { CloseOutline } from '@/icons';
import TamButton from '@/components/TamButton.vue';
import { ref } from 'vue';

const props = defineProps({
  filter: { type: Object, default: {} },
  filterVars: { type: Object, default: [] },
  months: { type: Array, default: [] },
  open: { type: Boolean },
  enums: { type: Object },
});

const dateSelected = ref('');
const level_of_experience = ref([
  { key: 'entry_level', value: 'Entry level' },
  { key: 'junior_level', value: 'Junior level' },
  { key: 'mid_level', value: 'Mid level' },
  { key: 'senior_level', value: 'Senior level' },
  { key: 'lead', value: 'Lead' },
  { key: 'executive', value: 'Executive' },
]);

const emit = defineEmits(['onClose', 'update', 'onApply', 'clear']);
const closeModal = () => {
  emit('onClose');
};

const apply = () => {
  emit('onApply');
};

const clear = () => {
  emit('clear');
};

const selectDate = (value) => {
  updateFilter('date-picked', 'date', value);
};

const updateFilter = (event, key, value = '') => {
  let dataVal = '';

  if (event == 'date-picked') {
    dataVal = value;
  } else if (event.target.type == 'select-one') {
    dataVal = event.target.options[event.target.options.selectedIndex].getAttribute('data-value');
  }

  emit('update', event, key, dataVal ? dataVal : value);
};
</script>

<style lang="scss" scoped>
.multiselect::v-deep {
  border: 1px solid #dedede !important;
  border-radius: 0.75rem !important;
}
</style>
