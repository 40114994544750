<template>
  <div>
    <div class="mb-5">
      <h1 class="text-[#1B224B] text-[32px] font-bold">
        How would you like to receive your applicants?
      </h1>
      <small class="text-[#1B224B] text-[15px]">Choose where potential candidates will be directed.</small>
    </div>

    <div class="pt-5">
      <div class="col-span-6 mb-6">
        <div class="flex items-center mb-4">
          <input v-model="details.apply_in_external_website" id="default-checkbox" type="checkbox"
            class="w-4 h-4 text-[#1EB12D] bg-gray-100 border-gray-300 rounded focus:ring-[#1EB12D] dark:focus:ring-[#1EB12D] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
          <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Applicants apply
            through an external website</label>
        </div>
      </div>

      <div class="col-span-6 mb-8">
        <input v-model="details.external_website_url" :disabled="!details.apply_in_external_website" name="external_url"
          type="url" id="email-address-icon"
          class="mt-1 block w-full border-1 border-[#1B224B1A] p-4 rounded-md placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm"
          placeholder="External application url" />
      </div>

      <div class="col-span-6 mb-6">
        <div class="flex items-center mb-4">
          <input v-model="details.apply_through_careerbuddy" id="default-checkbox" type="checkbox"
            class="w-4 h-4 text-[#1EB12D] bg-gray-100 border-gray-300 rounded focus:ring-[#1EB12D] dark:focus:ring-[#1EB12D] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            checked />
          <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Let applicants
            apply through CareerBuddy</label>
        </div>
      </div>
    </div>
    <div class="py-5 px-3 flex-col flex-start gap-2">
      <div class="flex-col">
        <div class="flex flex-col gap-2 items-start justify-start shrink-0 relative">
          <div class="text-[#1b224b] text-center relative" style="font: 600 15px 'Albert Sans', sans-serif">
            Automated Interview
          </div>
          <div class="text-[#1b224b] text-left relative w-80" style="
        font: 400 13px/150% 'Albert Sans', sans-serif;
        opacity: 0.800000011920929;
      ">
            Attract top talent: Unveiling a rewarding career opportunity. Add up to 5
            questions that each interested applicant has to answer.
          </div>
        </div>
        <div class="flex flex-col gap-4 items-start justify-start shrink-0 relative">
          <div class="mb-3 w-full">
            <div class="flex items-center mb-5">
              <input value="What is your notice Period?" type="text" readonly
                class="mt-1 block w-full border-1 border-[#1B224B1A] p-4 rounded-md placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm mr-3" />
            </div>
            <div class="flex items-center mb-5">
              <input value="What is your salary expectations? (Yearly)" type="text" readonly
                class="mt-1 block w-full border-1 border-[#1B224B1A] p-4 rounded-md placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm mr-3" />
            </div>

            <template v-if="details.automated_interview_questions.length > 0">
              <div class="flex items-center mb-5"
                v-for="(item, index) in details?.automated_interview_questions" :key="index">
                <input v-model="details.automated_interview_questions[index]" type="text"
                  :placeholder="`Interview question #${index + 3}`"
                  class="mt-1 block w-full border-1 border-[#1B224B1A] p-4 rounded-md placeholder:text-brand-black/50 focus:outline-none focus:ring-0 sm:text-sm mr-3" />
                <img src="@/assets/icons/cancel.svg" @click="removeQuestion(index)"
                  class="cursor-pointer w-[24px] h-[24px]" />
              </div>
            </template>
          </div>
          <div class="inline-flex items-center cursor-pointer" @click="addQuestion">
            <img src="@/assets/icons/plus.svg" class="mr-2" alt="" />
            <span class="inline-block text-[16px] text-[#E91868] font-semibold">Add</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  enums: { type: Object, default: () => ({}) },
  details: { type: Object, default: {} },
});

const addQuestion = () => {
  if (props.details.automated_interview_questions.length >= 3) {
    return;
  }
  props.details.automated_interview_questions.push('')
};

const removeQuestion = (index) => {
  props.details?.automated_interview_questions?.splice(index, 1);
};
</script>
