<template>
  <div>
    <h2 class="mb-8 text-[#1B224B] font-bold text-[24px]">
      {{ formatText(details.role_title) }}
    </h2>
    <div class="grid grid-cols-2 gap-8 border-b border-[#E6E6EA] pb-6">
      <div>
        <div class="mb-6">
          <h4 class="text-[#1B224B] font-bold text-[18px] ">
            {{
            formatText(details.work_type)
          }}
          </h4>
          <small class="text-[#1B224B] text-[15px]">Work Type</small>
        </div>

        <div class="mb-6">
          <h4 class="text-[#1B224B] font-bold text-[18px] ">
             {{ roleTitle }}
          </h4>
          <small class="text-[#1B224B] text-[15px]">Job Role</small>
        </div>

        <div class="mb-6">
          <h4 class="text-[#1B224B] font-bold text-[18px] ">
             {{ formatText(details.level_of_experience) }}
          </h4>
          <small class="text-[#1B224B] text-[15px]">Seniority</small>
        </div>
      </div>
      <!-- ... -->
      <div>
        <div class="mb-6">
          <h4 class="text-[#1B224B] font-bold text-[18px] "> {{
            display_location
          }}</h4>
          <small class="text-[#1B224B] text-[15px]">Location</small>
        </div>

        <div class="mb-6">
          <h4 class="text-[#1B224B] font-bold text-[18px] ">
            Who specializes in
          </h4>
          <div class="flex flex-wrap items-center mt-3">
            <div class="mr-4 mb-3 rounded-[30px] px-[16px] py-[8px] bg-[#F3F3F5] font-medium text-[15px]"
              v-for="(item, i) in details.skills" :key="i">
              <span class="mr-1">{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 border-b border-[#E6E6EA] pb-6">
      <div class="my-6">
        <h4 class="text-[#1B224B] font-bold text-[18px] ">
           {{ formatText(details.type) }}
        </h4>
        <small class="text-[#1B224B] text-[15px]">Employment type</small>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-8 border-b border-[#E6E6EA] py-6">
      <div>
        <div class="mb-6">
          <h4 class="text-[#1B224B] font-bold text-[18px] ">
            {{ details.salary_min || 0 }} – {{ details.salary_max || 0 }}
            {{ details.salary_currency }} / {{ details.pay_period }}
          </h4>
          <small class="text-[#1B224B] text-[15px]">Target group gross salary (before tax)</small>
        </div>
      </div>
      <!-- ... -->
      <div>
        <div class="mb-6">
          <div class="flex items-center mb-2">
            <div class="w-[40px] h-[40px] rounded-full bg-[#7440E2]/[.10] flex items-center justify-center mr-3">
              <img src="@/assets/icons/users2.svg" />
            </div>
            <h4 class="text-[#1B224B] font-bold text-[18px] ">
              {{ potentialReach }}
            </h4>
          </div>
          <small class="text-[#1B224B] text-[15px]">Potential Reach</small>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-8 border-b border-[#E6E6EA] py-6">
      <h4 class="text-[#1B224B] font-bold text-[18px] ">
            Automated interview questions
          </h4>
      <div class="flex items-center border-b py-1">
        <div class="text-[15px]">What is your notice Period?</div>
      </div>
      <div class="flex items-center border-b py-1">
        <div class="text-[15px]">What is your Salary Expectations? (Yearly)</div>

      </div>
      <div class="flex items-center border-b py-1" v-for="(question, idx) in details.automated_interview_questions" :key="idx">
        <div class="text-[15px] ">{{ question }}</div>
      </div>
    </div>

    <div class="grid grid-cols-1 pb-6">
      <div class="my-6">
        <div class="py-3 px-4 rounded-lg bg-[#FCFCFC]">
          <div class="flex items-center mr-4">
            <input type="radio" value="" name="experience"
              class="w-4 h-4 text-[#25D06A] bg-gray-100 border-gray-300 focus:ring-[#25D06A] dark:focus:ring-[#25D06A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              checked />
            <label for="green-radio" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">I do not want my
              job opening to be promoted on social media and to get free
              extra reach</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  details: { type: Object, default: {} },
  enums: { type: Array, default: [] },
  jobTitles: { type: Array, default: [] },
  potentialReach: { type: Number, default: 0 },
});

const store = useStore();

const roleTitle = computed(() => {
  return props.jobTitles.find((item) => item.key == props.details.title)?.value || "N/A";
});

const userDetails = computed(() => {
  return store.state.auth.userDetails;
});

const getState = (state_id) => {
  const r = store.getters['global/getStates'].find((y) => y.id === state_id)?.value;
  return r;
};

const getCountry = (country_id) => {
  const r = store.getters['global/getCountries'].find((y) => y.id === country_id)?.value;
  return r;
};

const display_location = computed(() => {
  const state = getState(props.details.state_id);
  const country = getCountry(props.details.country_id);
  if (country) {
    return state ? `${country} (${state})` : country;
  }
  if (state) {
    return `${state}`
  }
  return 'N/A';
});

const formatText = (text) => text?.replaceAll('_', ' ') || "N/A" 
</script>
