<template>
  <main>
    <form @submit.prevent="goNext">
      <div class="flex flex-col items-center mt-8 mb-44">
        <img class="w-44 h-44 mb-6" src="@/assets/images/Uploading.svg" alt="" />
        <p class="font-FoundersSemiBold text-xl mb-4">Drag & Drop here to upload</p>
        <p class="text-sm text-[#8F8F8F] font-normal mb-6">Or select file from your computer</p>
        <button type="button" class="bg-brand-black text-white md:px-6 md:py-5 p-4 rounded-[10px] cursor-pointer text-base font-bold">
          Upload File
          <input type="file" class="hidden" />
        </button>
      </div>
      <div class="mt-8 grid md:grid-cols-2 md:gap-x-5 md:gap-y-6 gap-4">
        <button
          type="button"
          class="w-full border-brand-black text-brand-black border md:px-5 md:py-4 p-4 rounded-lg disabled:bg-opacity-80 cursor-pointer"
          @click="close"
        >
          Cancel
        </button>
        <button
          type="submit"
          :disabled="checkDisability"
          class="w-full bg-brand-black text-white md:px-5 md:py-4 p-4 rounded-lg disabled:bg-opacity-80 disabled:cursor-not-allowed"
        >
          {{ loading ? 'Loading...' : 'Submit' }}
          <btn-loader :loading="loading" />
        </button>
      </div>
    </form>
  </main>
</template>

<script setup>
import { computed, ref } from 'vue';

const disabled = ref(true);
const loading = ref(false);

const goNext = () => {
  console.log('here');
};
const emit = defineEmits(['onClose']);

const close = () => {
  emit('onClose');
};

const checkDisability = computed(() => {
  // return !candidate ? (disabled.value = false) : (disabled.value = true);
  // return (userDetails.value.first_name && userDetails.value.last_name && userDetails.value.email !== '') || userDetails.value.company_name !== ''
  //   ? (disabled.value = false)
  //   : (disabled.value = true);
});
</script>
