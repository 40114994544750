<template>
  <div class="flex items-center mb-4">
    <img src="@/assets/icons/check.svg" class="mr-2" alt="" />
    <small class="text-[#000000]/[.7] text-[12px] font-normal">
      {{ isString(title) ? title : `${title?.quantity ? `(${title?.quantity})` : ''} ${getFeatureTitle(title?.feature_id) || title?.description}` }}
    </small>
  </div>
</template>

<script setup>
const props = defineProps({
  title: { type: String, default: '' },
  features: { type: Array, default: [] },
});

const isString = (value) => {
  return typeof value == 'string';
};

const getFeatureTitle = (id) => {
  return props.features.find((item) => item.id === id)?.name || '';
};
</script>
