<template>
  <Dialog v-show="isActive" class="backdrop-blur-sm" :extend-class="['md:max-w-xl mb-[2rem]', tab == 1? 'mt-[30rem]' : 'mt-[22rem]']">
    <div class="my-6 bg-white rounded- w-full">
      <div class="flex justify-between px-6">
        <div class="w-full">
          <div class="flex justify-between items-start">
            <div class="mb-4">
              <h1 class="text-[20px] font-semibold leading-[130%] text-brand-black mb-2">Add Candidate to Operation Research</h1>
              <div class="flex space-x-3 items-center">
                <img src="../../assets/icons/infoline.svg" alt="" />
                <small class="text-light-gray">You are inviting someone to apply for this job</small>
              </div>
            </div>

            <img src="../../assets/icons/closeline.svg" class="cursor-pointer" alt="" @click="close" />
          </div>

          <ul class="flex flex-wrap mb-4 border-b border-gray-200">
            <li class="mr-2">
              <a
                href="#"
                @click.prevent="tab = 1"
                aria-current="page"
                :class="{ 'border-b-2 border-brand-black font-semibold': tab === 1 }"
                class="inline-block p-4 rounded-t-lg dark:bg-gray-800 text-[14px]"
                >Manual</a
              >
            </li>
            <li class="mr-2">
              <a
                href="#"
                @click.prevent="tab = 2"
                :class="{ 'border-b-2 border-brand-black font-semibold': tab === 2 }"
                class="inline-block p-4 rounded-t-lg hover:text-gray-600 dark:hover:bg-gray-800 dark:hover:text-gray-300 text-[14px] text-black/[.5]"
                >Resume</a
              >
            </li>
            <li class="mr-2">
              <a
                href="#"
                @click.prevent="tab = 3"
                :class="{ 'border-b-2 border-brand-black font-semibold': tab === 3 }"
                class="inline-block p-4 rounded-t-lg hover:text-gray-600 dark:hover:bg-gray-800 dark:hover:text-gray-300 text-[14px] text-black/[.5]"
                >CSV</a
              >
            </li>
          </ul>

          <add-candidate-to-job-manual :inviteDetails="inviteDetails" v-if="tab == 1" />
          <add-candidate-to-job-resume :inviteDetails="inviteDetails" @confirm="goToManualTab" v-else-if="tab == 2" />
          <add-candidate-to-job-CSV :jobId="jobId" @close="close" v-else />

          <div class="pt-2 flex space-x-6 items-center" v-if="tab != 3">
            <button type="button" @click.prevent="close" class="bg-white p-4 py-3 border border-brand-black rounded-lg w-1/2">Cancel</button>
            <button @click="confirm" class="bg-brand-black text-white p-4 py-3 rounded-lg w-1/2">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import Dialog from '@/components/Dialog.vue';
import AddCandidateToJobManual from './AddCandidateToJobManual.vue';
import AddCandidateToJobResume from './AddCandidateToJobResume.vue';
import AddCandidateToJobCSV from './AddCandidateToJobCSV.vue';
import { ref, onMounted } from 'vue';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { errorMessage } from '@/utils/helper';

const props = defineProps({
  isActive: { type: Boolean, default: false },
  jobId: { type: String },
});

const toast = useToast();
const store = useStore();
const loading = ref(false);
const inviteDetails = ref({
  email: '',
  first_name: '',
  last_name: '',
  employer_job_id: '',
  phone_no: '',
  current_company: '',
  job_title: '',
  qualification: '',
  tag: [],
  note: '',
  years_of_experience: '',
});

const emit = defineEmits(['confirm', 'close']);
const tab = ref(1);

const confirm = () => {
  const tabNum = tab.value;
  if (tabNum == 1) {
    addCandidateJobManual();
  } else if (tabNum == 2) {
    emit('confirm', inviteDetails.value, 'resume');
  }
};

const close = () => {
  emit('close');
};

const goToManualTab = () => {
  toast.info('Information updated. Kindly fill and submit the form', {
    timeout: 3000,
    hideProgressBar: true,
  });
  tab.value = 1;
};

const addCandidateJobManual = async () => {
  try {
    loading.value = true;
    const { message } = await store.dispatch('global/jobInviteManual', { data: inviteDetails.value, jobId: props.jobId });

    toast.success(message, {
      timeout: 3000,
      hideProgressBar: true,
    });

    close();
  } catch (error) {
    toast.error(errorMessage(error) || 'Something went wrong (Please check internent connection)', {
      timeout: 5000,
      hideProgressBar: true,
    });
    loading.value = false;
  }
};

onMounted(() => {
  tab.value = 1;
});
</script>
