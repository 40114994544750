<template>
  <overlay v-show="value" @overlay-click="cancel">
    <card-component
      v-show="value"
      :title="title"
      class="shadow-lg w-full max-h-modal md:w-3/5 lg:w-2/5 z-50"
      rounded="rounded-lg"
      :header-icon="mdiClose"
      modal
      @header-icon-click="cancel"
    >
      <div class="space-y-3">
        <h1 v-if="largeTitle" class="text-2xl">
          {{ largeTitle }}
        </h1>
        <slot />
      </div>

      <tam-buttons v-slot="footer">
        <tam-button v-if="hasDone" :label="buttonLabel" :color="button" @click="confirm" />
        <tam-button
          v-if="hasCancel"
          label="Cancel"
          :color="button"
          outline
          @click="cancel"
        />
      </tam-buttons>
    </card-component>
  </overlay>
</template>

<script>
import { mdiClose } from '@mdi/js'
import TamButton from '@/components/TamButton.vue'
import TamButtons from '@/components/TamButtons.vue'
import CardComponent from '@/components/CardComponent.vue'
import Overlay from '@/components/Overlay.vue'

export default {
  props: {
    title: {
      type: String,
      default: null
    },
    largeTitle: {
      type: String,
      default: null
    },
    button: {
      type: String,
      default: 'info'
    },
    buttonLabel: {
      type: String,
      default: 'Done'
    },
    hasCancel: Boolean,
    hasDone: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: null
    }
  },
  components: {
    Overlay,
    TamButtons,
    CardComponent,
    TamButton
  },
  data () {
    return {
      mdiClose
    }
  },
  emit: ['update:modelValue', 'cancel', 'confirm'],
  computed: {
    value () {
      return {
        get: () => this.modelValue,
        set: value => this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    confirmCancel (mode) {
      this.value = false
      this.$emit(mode)
    },
    confirm () {
      this.confirmCancel('confirm')
    },
    cancel () {
      this.confirmCancel('cancel')
    }
  }
}
</script>
