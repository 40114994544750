<template>
  <main class="z-50 fixed inset-0">
    <div class="relative px-4 min-h-screen md:flex md:items-center md:justify-center">
      <div class="w-full h-full fixed inset-0 bg-black/70"></div>
      <div class="inset-x-0 bottom-0 z-50 md:relative fixed w-full max-h-[900px] h-full overflow-y-scroll md:max-w-2xl">
        <div class="px-8 pb-8 pt-3 w-full bg-white md:rounded rounded-b-none rounded-lg relative">
          <div class="top-0 sticky z-50 bg-white flex itemscenter justify-between">
            <div class="pt-5">
              <p class="text-[#1A1A1A] text-2xl font-semibold mb-2.5">Add Candidate to {{ jobDetails.role_title }}</p>
              <p class="text-sm text-[#8F8F8F] font-normal">You are inviting someone to apply for this job</p>
            </div>
            <p @click="closeModal" class="pt-5 cursor-pointer">X</p>
          </div>
          <div>
            <Tab :numberOfTabs="tabs" :currentTab="tabNumber" borderColor="border-brand-black" @changeTab="changeTab" spread overflow>
              <div class="rounded-lg w-full">
                <div v-if="tabNumber == 1" id="about" role="tabpanel" aria-labelledby="about-tab" class="grid mt-2">
                  <manual @on-close="closeModal" :info="jobDetails" />
                </div>
                <div v-if="tabNumber == 2" id="location" role="tabpanel" aria-labelledby="location-tab" class="grid mt-2">
                  <resume @on-close="closeModal" />
                </div>
                <div v-if="tabNumber == 3" id="social" role="tabpanel" aria-labelledby="social-tab" class="grid mt-2">
                  <csv />
                </div>
              </div>
            </Tab>
          </div>
          <!-- <div><slot name="footer" /></div> -->
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref } from 'vue';
import Tab from '../Tab.vue';
import Manual from './invite/Manual.vue';
import Resume from './invite/Resume.vue';
import Csv from './invite/Csv.vue';

const tabNumber = ref(1);

const tabs = ref([
  {
    name: 'Manual',
  },
  {
    name: 'Resume',
  },
  {
    name: 'CSV',
  },
]);

const changeTab = (value) => {
  tabNumber.value = value;
  // window.scrollTo(0, 50);
};

const props = defineProps({
  jobDetails: { type: Object },
});

const emit = defineEmits(['onClose']);

const closeModal = () => {
  emit('onClose');
};
</script>
